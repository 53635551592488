import React from 'react';

import { SampleDetailsDialog } from '@/components/shared/SampleDetailsDialog/SampleDetailsDialog';

import { ReadPropertyConfigSchema, useRetrieveInteraction } from '@/helpers/services/api';
import { getAppStorage } from '@/helpers/utils/localStorage';

interface PropertyDrillDownDialogProps {
  currentInteractionId: string;
  open: boolean;
  closeDialog: () => void;
  updateAnnotation: (interactionId: string, annotation: string, reason: string, isEstimated?: boolean) => void;
  listOfProperties: ReadPropertyConfigSchema[];
}

export const PropertyDrillDownDialog = ({
  currentInteractionId,
  open,
  closeDialog,
  updateAnnotation,
  listOfProperties
}: PropertyDrillDownDialogProps) => {
  const { versionId, appId } = getAppStorage();
  const { data, isFetching } = useRetrieveInteraction(versionId, currentInteractionId);

  if (isFetching) {
    return <></>;
  }

  return (
    <SampleDetailsDialog
      sample={data}
      open={open}
      closeDialog={closeDialog}
      isFetching={isFetching}
      updateAnnotation={updateAnnotation}
      appId={appId}
      orderingProps
      listOfProperties={listOfProperties}
    />
  );
};
