import { Stack, styled } from '@mui/material';

import { StyledContainer, StyledInput, StyledLoader, StyledText } from '@/components/lib';

export const PropertiesContainer = styled(StyledContainer)(() => ({
  gap: '24px',
  width: '100%',
  padding: '24px'
}));

export const PropertiesHeaderContainer = styled(StyledContainer)(({ theme }) => ({
  gap: '18px',
  display: 'grid',
  alignItems: 'center',
  padding: '0 2px 0 6px',
  gridTemplateColumns: 'auto 24px 72px',

  '.MuiSvgIcon-root': {
    cursor: 'pointer',
    color: theme.palette.grey[300],

    ':hover': { borderRadius: '8px', backgroundColor: theme.palette.grey[100] }
  }
}));

export const PropertiesAddContainer = styled(Stack)(({ theme }) => ({
  cursor: 'pointer',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center',

  '.MuiSvgIcon-root': { color: theme.palette.grey[300] },
  '.MuiTypography-root': { color: theme.palette.grey[300] },

  ':hover': { borderRadius: '8px', backgroundColor: theme.palette.grey[100] }
}));

export const PropertiesInnerContainer = styled(StyledContainer)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
  gap: '16px',
  padding: '0',

  [theme.breakpoints.down('xl')]: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))'
  }
}));

export const PropertiesDialogInnerContainer = styled(StyledContainer)(() => ({
  gap: '24px'
}));

export const PropertiesDialogTextInput = styled(StyledInput)(({ theme }) => ({
  height: '44px',
  color: theme.palette.common.black
}));

export const PropertiesAddCardText = styled(StyledText)(({ theme }) => ({
  margin: 'auto',
  color: theme.palette.grey[400]
}));

export const LoaderContainer = styled(StyledContainer)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  height: '30vh'
}));

export const PropertiesLoader = styled(StyledLoader)(() => ({
  margin: '200px auto'
}));

export const PropertiesErrText = styled(StyledText)(({ theme }) => ({
  color: theme.palette.error.main,
  maxWidth: '466px'
}));
