export interface resError {
  error_message: string;
  additional_information?: { [key: string]: string };
}

export const openResPopup = (message: string, message_type?: 'success' | 'error') => {
  const currentQuery = typeof window !== 'undefined' ? window.location.search : '';

  return currentQuery
    ? `${currentQuery}&message=${message}&message_type=${message_type ?? 'info'}`
    : `?message=${message}&message_type=${message_type ?? 'info'}`;
};
