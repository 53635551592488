import { PropertyType } from '@/helpers/services/api';

import { capitalizeFirstLetter } from '@/helpers/utils/capitalizeFirstLetter';

export const propertyConditionsDropdownValues = [
  { label: 'Greater than or equal', value: 'GE' },
  { label: 'Less than or equal', value: 'LE' }
];

export const propertyKindsDropdownValues = Object.values(PropertyType).map((type: string) => ({
  label: capitalizeFirstLetter(type),
  value: type
}));
