import { Operator } from '../services/api';

export function operatorToSign(operator?: Operator) {
  switch (operator) {
    case 'GE':
      return '>=';
    case 'GT':
      return '>';
    case 'LE':
      return '<=';
    case 'LT':
      return '<';
    case 'EQ':
      return '==';
    case 'NEQ':
      return '!=';
    case 'IN':
      return 'in';
    case 'NIN':
      return 'not in';
    case 'OVERLAP':
      return 'overlaps';
    default:
      return '>';
  }
}

export function operatorToSignRight(operator?: Operator) {
  switch (operator) {
    case 'GE':
      return '=<';
    case 'GT':
      return '<';
    case 'LE':
      return '>=';
    case 'LT':
      return '>';
    case 'EQ':
      return '==';
    case 'NEQ':
      return '!=';
    case 'OVERLAP':
      return '&&';
    default:
      return '<';
  }
}
