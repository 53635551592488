export const constants = {
  buttonLabel: 'See Interactions',
  highestValues: 'Highest values',
  lowestValues: 'Lowest values',
  noPropertyMessage: 'No data exists about this property for the selected application',
  distributionOverTime: {
    margin: '0 auto 24px',
    width: '100%'
  },
  limit: 2,
  chart: {
    title: 'Score Distribution',
    subtitle: 'Score',
    yAxisName: 'Density'
  }
};
