import React from 'react';

import { OverviewNoDataAvailable } from '../../OverviewNoDataAvailable/OverviewNoDataAvailable';

import { NoInsightContainer } from '../Insights.styles';

import { constants } from '../../overview.constants';

const { description } = constants.insights.noInsights;

export const NoInsights = () => (
  <NoInsightContainer>
    <OverviewNoDataAvailable description={description} type="Insights" noUploadDataBtn />
  </NoInsightContainer>
);
