import React from 'react';

import { StackProps } from '@mui/material';

import { StyledSkeleton, StyledToolTip } from '@/components/lib';
import { InOutIcon } from '@/components/shared/InOutIcon/InOutIcon';

import {
  StyledContainer,
  StyledArrowBackIcon,
  StyledValuesContainer,
  StyledCondition,
  StyledOperator,
  StyledValue,
  StyledValues,
  StyledName,
  StyledValueLabel,
  StyledOrderDropdown,
  StyledInfoIcon
} from './PropertyDrillDownHeader.styles';
import { StyledSeeAllSamplesButton } from '../PropertyDrillDown.styles';

import { operatorToSign } from '@/helpers/utils/operatorToSign';
import { useScoreColor } from '@/helpers/hooks/useScoreColor';
import {
  ReadPropertyConfigSchema,
  ListPropertiesDataApiV1ApplicationVerionsAppVersionIdPropertiesConfigDataGet200,
  Operator,
  EnvType
} from '@/helpers/services/api';

import { constants } from './propertyDrillDownHeader.constants';
import { constants as propertyDrillDownConstants } from '../propertyDrillDown.constants';

interface PropertyDrillDownHeaderProps extends StackProps {
  name: string;
  orderType: string;
  isCategorical?: boolean;
  isFetching: boolean;
  isDataAvailable: boolean;
  selectedEnv: string;
  currentProperty: ReadPropertyConfigSchema;
  propertyInfo: { description: string; url: string };
  headerData: ListPropertiesDataApiV1ApplicationVerionsAppVersionIdPropertiesConfigDataGet200 | undefined;
  handleRedirectToSamples: () => void;
  setOrderType: React.Dispatch<React.SetStateAction<string>>;
  setCurrentProperty: React.Dispatch<ReadPropertyConfigSchema | null>;
}

const { avgValueTitle, conditionTitle, linkTooltip } = constants;
const { highestValues, lowestValues, buttonLabel } = propertyDrillDownConstants;

export const PropertyDrillDownHeader = (props: PropertyDrillDownHeaderProps) => {
  const {
    name,
    currentProperty,
    orderType,
    isDataAvailable,
    selectedEnv,
    propertyInfo,
    isCategorical,
    isFetching,
    headerData,
    setOrderType,
    setCurrentProperty,
    handleRedirectToSamples,
    ...otherProps
  } = props;

  const { condition, threshold, id } = currentProperty;
  const value = headerData && headerData[selectedEnv]?.[id];

  const isProd = selectedEnv === EnvType['PROD'];
  const valuesContainerBg = useScoreColor(Number(value), condition as Operator, Number(threshold));

  return (
    <StyledContainer {...otherProps}>
      <StyledArrowBackIcon onClick={() => setCurrentProperty(null)} />
      <StyledName text={name} type="h3" contrast="low" />
      <InOutIcon kind={currentProperty.kind} margin="0 8px 0 0" />
      <StyledToolTip text={propertyInfo.description} link={{ href: propertyInfo.url, label: linkTooltip }}>
        <StyledInfoIcon />
      </StyledToolTip>
      {isProd ? (
        <StyledSeeAllSamplesButton label={buttonLabel} onClick={handleRedirectToSamples} variant="text" />
      ) : (
        !isCategorical && (
          <>
            <StyledValuesContainer>
              {isFetching ? (
                <StyledSkeleton height="40px" width="240px" />
              ) : value ? (
                <>
                  <StyledValueLabel text={avgValueTitle} />
                  <StyledValues backgroundColor={valuesContainerBg}>
                    <StyledValue text={typeof value === 'number' ? +value?.toFixed(2) : 0} />
                    <StyledOperator text={operatorToSign(condition as Operator)} />
                    <StyledCondition text={threshold} />
                  </StyledValues>
                  <StyledValueLabel text={conditionTitle} />
                </>
              ) : (
                <></>
              )}
            </StyledValuesContainer>
            {isDataAvailable && (
              <StyledOrderDropdown
                data={[{ name: highestValues }, { name: lowestValues }]}
                value={orderType}
                setValue={setOrderType}
              />
            )}
          </>
        )
      )}
    </StyledContainer>
  );
};
