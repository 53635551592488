import { DataFilter } from '../services/api';
import { operatorToSign, operatorToSignRight } from '@/helpers/utils/operatorToSign';

export const calculateDataFilterString = (arr?: DataFilter[]) => {
  if (Array?.isArray(arr)) {
    const groupedFilters = arr.reduce((accumulator: { [key: string]: DataFilter[] }, filter) => {
      if (filter.column) {
        const key = `${filter?.column}_${filter?.property_type}`;

        if (!accumulator[key as keyof typeof accumulator]) {
          // eslint-disable-next-line no-param-reassign
          accumulator[key as keyof typeof accumulator] = [];
        }

        (accumulator[key as keyof typeof accumulator] as DataFilter[]).push(filter);
      }
      return accumulator;
    }, {});

    const combinedFilters = Object.values(groupedFilters).map((filters: DataFilter[]) => {
      const type = filters[0]?.property_type === 'input' ? '(input)' : '';

      if (filters?.length === 1) {
        const filter = filters[0];

        return `${filter?.column} ${operatorToSign(filter?.operator)} ${
          typeof filter?.value === 'number' ? Number(filter?.value)?.toFixed(2) : filter?.value
        } ${type}`;
      } else {
        const combinedFilterString = filters
          .map((filter: DataFilter, i: number) => {
            return ` ${i === 1 ? `${operatorToSign(filter?.operator)} ` : ''}${
              typeof filter?.value === 'number' ? Number(filter?.value)?.toFixed(2) : filter?.value
            } ${i === 0 ? `${operatorToSignRight(filter?.operator)}  ${filters[0]?.column}` : ''}`;
          })
          .join(' ');

        return ` ${combinedFilterString} ${type}`;
      }
    });

    return [...combinedFilters];
  } else {
    return [];
  }
};
