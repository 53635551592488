import React, { useEffect, useState } from 'react';

import { RefreshRounded } from '@mui/icons-material';

import { Insight } from './Insight/Insight';
import { NoInsights } from './NoInsights/NoInsights';
import { NewInsightsAvailable } from './NewInsightsAvailable/NewInsightsAvailable';

import { StyledSkeleton, StyledText } from '@/components/lib';
import {
  InsightsContainer,
  InsightsRefreshButton,
  InsightsScrollContainer,
  InsightsHeaderContainer
} from './Insights.styles';

import {
  EnvType,
  InsightSchema,
  useGetAppLastUpdateTime,
  useGetInsightApiV1InsightsGet,
  recalculateInsightsApiV1InsightsRecalculatePost
} from '@/helpers/services/api';

import { constants } from '../overview.constants';

interface InsightsProps {
  envType: EnvType;
  interactionType: string;
  selectedVersionId: number;
}

export const Insights = (props: InsightsProps) => {
  const { envType, selectedVersionId, interactionType } = props;

  const [isRecalculationDisabled, setIsRecalculationDisabled] = useState(false);

  const {
    data: insightsData,
    isLoading,
    refetch
  } = useGetInsightApiV1InsightsGet({
    env_type: envType,
    interaction_type_name: interactionType,
    application_version_id: selectedVersionId
  });
  const { data: sysInfoData } = useGetAppLastUpdateTime({
    application_version_id: selectedVersionId,
    env_type: envType
  });

  const isProd = envType === EnvType['PROD'];
  const isPenTest = envType === EnvType['PENTEST'];

  const isRecalculateAvailable = !!sysInfoData?.insights?.can_execute_calculation;

  const handleRecalculationClick = () => {
    recalculateInsightsApiV1InsightsRecalculatePost({
      environment: envType,
      application_version_id: selectedVersionId
    });
    setIsRecalculationDisabled(true);
  };

  useEffect(() => {
    // After the recalculation is done, enable the recalculation button for another versions
    if (isRecalculationDisabled) {
      setIsRecalculationDisabled(false);
    }
  }, [selectedVersionId, envType]);

  if (isPenTest || isProd) {
    return <></>;
  } else if (!insightsData?.length && !isLoading) {
    return <NoInsights />;
  }

  return (
    <InsightsContainer data-testid="InsightsContainer">
      <InsightsHeaderContainer>
        <StyledText text={constants.insights.title} type="h3" contrast="low" />
        {isRecalculateAvailable && (
          <InsightsRefreshButton
            disabled={isRecalculationDisabled}
            label={constants.insights.refreshBtnLabel}
            startIcon={<RefreshRounded />}
            onClick={handleRecalculationClick}
          />
        )}
      </InsightsHeaderContainer>
      <InsightsScrollContainer>
        {isRecalculateAvailable && <NewInsightsAvailable />}
        {isLoading
          ? [...Array(3)].map(index => <StyledSkeleton key={index} height="155px" width="100%" />)
          : insightsData?.map((insight: InsightSchema, i) => (
              <Insight key={insight?.insight_type + i} insight={insight} refetch={refetch} />
            ))}
      </InsightsScrollContainer>
    </InsightsContainer>
  );
};
