import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { SubjectOutlined, PieChartOutlineOutlined, FlashlightOnOutlined, DataUsageOutlined } from '@mui/icons-material';

import { routes } from '@/helpers/routes';

import {
  OverviewNoDataAvailableContainer,
  OverviewNoDataAvailableButton,
  StyledImageContainer,
  StyledTitleText,
  StyledSubTitleText,
  StyledDescriptionText
} from './OverviewNoDataAvailable.styles';

import { constants } from './overviewNoDataAvailable.constants';

const { noDataAvailable, pleaseUploadData, here } = constants;

interface OverviewNoDataAvailableProps {
  type?: string;
  title?: string;
  subTitle?: string;
  description?: string;
  redirectLink?: string;
  noUploadDataBtn?: boolean;
}

export const OverviewNoDataAvailable = ({
  type,
  title,
  subTitle,
  description,
  redirectLink,
  noUploadDataBtn
}: OverviewNoDataAvailableProps) => {
  const [isShowEmptyState, setIsShowEmptyState] = useState(false);

  const { push } = useRouter();

  const handleGoToOnboardingPage = () => push(redirectLink || routes.onboarding);

  const getCurIcon = () => {
    switch (type) {
      case 'Topics':
        return <SubjectOutlined fontSize="large" />;

      case 'Properties':
        return <FlashlightOnOutlined fontSize="large" />;

      case 'Insights':
        return <DataUsageOutlined fontSize="large" />;

      default:
        return <PieChartOutlineOutlined fontSize="large" />;
    }
  };

  useEffect(() => {
    const timeoutShowEmptyState = setTimeout(() => {
      setIsShowEmptyState(true);
    }, 1000);

    return () => clearTimeout(timeoutShowEmptyState);
  }, []);

  if (!isShowEmptyState) {
    return <></>;
  }

  return (
    <OverviewNoDataAvailableContainer>
      <StyledImageContainer type={type}>{getCurIcon()}</StyledImageContainer>
      <StyledTitleText text={title || noDataAvailable} type="h2" />
      {description && <StyledDescriptionText text={description} type="h3" />}
      {!noUploadDataBtn && (
        <StyledSubTitleText
          text={
            <>
              {subTitle || pleaseUploadData}
              <OverviewNoDataAvailableButton
                text={here}
                onClick={handleGoToOnboardingPage}
                data-testid={`noDataAvailable${type}`}
                type="h3"
              />
            </>
          }
          type="h3"
        />
      )}
    </OverviewNoDataAvailableContainer>
  );
};
