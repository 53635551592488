import React, { useState } from 'react';

import { MenuProps } from '@mui/material';
import { EditOutlined, HighlightOffOutlined } from '@mui/icons-material';

import { AddOrEditPropertyDialog } from '../../AddOrEditPropertyDialog/AddOrEditPropertyDialog';

import { StyledText } from '@/components/lib';
import { StyledMenuItem, StyledRootMenu } from './PropertyMenu.styles';

import { getAppStorage } from '@/helpers/utils/localStorage';
import { ReadPropertyConfigSchema, createOrUpdatePropertyConfig } from '@/helpers/services/api';

import { constants } from '@/components/Overview/overview.constants';

interface PropertyMenuProps extends MenuProps {
  propertyData: ReadPropertyConfigSchema;
  refetchProperties: () => void;
  handleCloseRootMenu: () => void;
}

export const PropertyMenu = (props: PropertyMenuProps) => {
  const { propertyData, handleCloseRootMenu, refetchProperties, ...otherProps } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { appId } = getAppStorage();

  const handleOpenEditDialog = () => {
    setIsDialogOpen(true);
    handleCloseRootMenu();
  };

  const handleCloseEditDialog = () => setIsDialogOpen(false);

  const handleDeleteProperty = async () => {
    await createOrUpdatePropertyConfig({
      application_id: appId,
      kind: propertyData?.kind,
      condition: propertyData?.condition,
      threshold: propertyData?.threshold,
      text_property: propertyData?.text_property,
      interaction_type: propertyData?.interaction_type,
      in_dashboard: false,
      is_hidden: false
    }).then(() => {
      handleCloseRootMenu();
      refetchProperties();
    });
  };

  return (
    <>
      <StyledRootMenu
        onClose={handleCloseRootMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        {...otherProps}
      >
        <StyledMenuItem onClick={handleOpenEditDialog}>
          <EditOutlined />
          <StyledText text={constants.properties.propertyMenu.condition} />
        </StyledMenuItem>
        <StyledMenuItem onClick={handleDeleteProperty}>
          <HighlightOffOutlined />
          <StyledText text={constants.properties.propertyMenu.remove} />
        </StyledMenuItem>
      </StyledRootMenu>
      <AddOrEditPropertyDialog
        open={isDialogOpen}
        selectedAppId={appId}
        propertyData={propertyData}
        interactionType={propertyData?.interaction_type ?? ''}
        closeDialog={handleCloseEditDialog}
        refetchProperties={refetchProperties}
        closeMenu={handleCloseRootMenu}
      />
    </>
  );
};
