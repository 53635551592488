import React from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

import { StyledText } from '@/components/lib';

import { routes } from '@/helpers/routes';

import { OverviewNoDataAvailable } from '../OverviewNoDataAvailable/OverviewNoDataAvailable';

import { InsightsEmptyContainer } from './OverviewNoVersionSelected.styles';
import { CardContainer, CardDescriptionContainer } from '../Summary/Summary.styles';
import { TopicsContainer, TopicsHeaderContainer } from '../Topics/Topics.styles';
import { PropertiesContainer, PropertiesHeaderContainer } from '../Properties/Properties.styles';
import { OverViewPropertiesContainer, OverviewInnerGrid, SummaryTopicsContainer } from '../Overview.styles';

import { constants } from './overviewNoVersionSelected.constants';

const { summaryTitle, noVersion, subTitle, topicsTitle, propertiesTitle } = constants;

interface OverviewNoVersionSelectedProps {
  isPenTest: boolean;
}

export const OverviewNoVersionSelected = ({ isPenTest }: OverviewNoVersionSelectedProps) => {
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('xl'));

  return (
    <OverviewInnerGrid pentest={isPenTest}>
      <OverViewPropertiesContainer data-testid="OverViewPropertiesContainer">
        <SummaryTopicsContainer>
          <CardContainer>
            <CardDescriptionContainer type="card">
              <StyledText text={summaryTitle} type="h3" contrast="low" />
              <OverviewNoDataAvailable subTitle={subTitle} title={noVersion} redirectLink={routes.config.versions} />
            </CardDescriptionContainer>
            <CardDescriptionContainer type="card" />
          </CardContainer>
          <TopicsContainer type="card" data-testid="TopicsContainer">
            <TopicsHeaderContainer>
              <StyledText text={topicsTitle} type="h3" contrast="low" />
            </TopicsHeaderContainer>
            <OverviewNoDataAvailable
              type="Topics"
              subTitle={subTitle}
              title={noVersion}
              redirectLink={routes.config.versions}
            />
          </TopicsContainer>
        </SummaryTopicsContainer>
        {!isDesktop && (
          <InsightsEmptyContainer>
            <StyledText text={constants.segments} type="h3" contrast="low" />
            <OverviewNoDataAvailable
              type="Insights"
              title={noVersion}
              subTitle={subTitle}
              redirectLink={routes.config.versions}
            />
          </InsightsEmptyContainer>
        )}
        <PropertiesContainer type="card" data-testid="PropertiesContainer">
          <PropertiesHeaderContainer>
            <StyledText type="h3" contrast="low" text={propertiesTitle} />
          </PropertiesHeaderContainer>
          <OverviewNoDataAvailable
            type="Properties"
            subTitle={subTitle}
            title={noVersion}
            redirectLink={routes.config.versions}
          />
        </PropertiesContainer>
      </OverViewPropertiesContainer>
      {isDesktop && (
        <InsightsEmptyContainer>
          <StyledText text={constants.segments} type="h3" contrast="low" />
          <OverviewNoDataAvailable
            type="Insights"
            title={noVersion}
            subTitle={subTitle}
            redirectLink={routes.config.versions}
          />
        </InsightsEmptyContainer>
      )}
    </OverviewInnerGrid>
  );
};
