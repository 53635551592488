import React, { useState, useEffect } from 'react';

import { StyledText, StyledToolTip } from '@/components/lib';
import { AnnotationType, TextScore, TextScoreAnnotation } from '@/helpers/services/api';

import { AnnotationCell } from '@/components/shared/SamplesAnnotationCell/SamplesAnnotationCell';
import { AnnotationAndScoreDivider } from './AnnotationAndScoreDivider/AnnotationAndScoreDivider';

import {
  AnnotationAndScoreWrapper,
  AnnotationAndScoreContainer,
  StyledTextButton,
  StyledInputText,
  StyledResponseText,
  StyledScoreBorderContainer,
  ScoreWrapper,
  AnnotationCellWrapper
} from './AnnotationAndScore.styles';

interface AnnotationAndScoreProps {
  sample: TextScore;
  isCompare?: boolean;
  isDrillDown?: boolean;
  propertyName?: string;
  handleOpenDialog: (interactionId: string) => void;
}

const constants = {
  viewMore: 'View More',
  scoreTooltip: (propertyName: string) => `${propertyName} Score`
};

const { viewMore, scoreTooltip } = constants;

export const AnnotationAndScore = (props: AnnotationAndScoreProps) => {
  const { sample, isCompare, propertyName, handleOpenDialog, isDrillDown } = props;
  const { input, output, user_interaction_id, annotation: sampleAnnotation, score, estimated_annotation } = sample;

  const [baseAnnotation, setBaseAnnotation] = useState<TextScoreAnnotation>(sampleAnnotation);

  const formattedScore = typeof score === 'number' ? Number(score)?.toFixed(2) : null;

  const handleDetailsClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    handleOpenDialog && handleOpenDialog(user_interaction_id);
  };

  const updateAnnotation = (
    _interactionId: string,
    annotation: string,
    reason: string,
    isEstimated?: boolean,
    deleteAnnotation?: boolean
  ) => {
    setBaseAnnotation(
      deleteAnnotation
        ? null
        : {
            updated_at: baseAnnotation?.updated_at ?? '',
            created_at: baseAnnotation?.created_at ?? '',
            is_estimated: isEstimated ? true : false,
            value: annotation as AnnotationType,
            reason: reason,
            origin: baseAnnotation?.origin ?? null,
            similarity_reason: baseAnnotation?.similarity_reason ?? null
          }
    );
  };

  useEffect(() => {
    setBaseAnnotation(sampleAnnotation);
  }, [sampleAnnotation]);

  return (
    <AnnotationAndScoreContainer is_compare={isCompare ? 'true' : 'false'}>
      <StyledInputText text={input} is_compare={isCompare ? 'true' : 'false'} />
      <AnnotationAndScoreDivider />
      <StyledResponseText
        text={output}
        is_compare={isCompare ? 'true' : 'false'}
        is_drill_down={isDrillDown ? 'true' : 'false'}
      />
      <AnnotationAndScoreWrapper>
        <StyledTextButton
          variant="text"
          label={viewMore}
          onClick={e => handleDetailsClick(e)}
          data-testid={`viewMore${user_interaction_id}`}
        />
        <ScoreWrapper data-testid="ScoreWrapper">
          <AnnotationCellWrapper is_compare={isCompare ? 'true' : 'false'}>
            <AnnotationCell
              annotation={baseAnnotation}
              interactionId={user_interaction_id}
              updateAnnotation={updateAnnotation}
              estAnnotation={estimated_annotation}
            />
          </AnnotationCellWrapper>
          {formattedScore && propertyName && (
            <StyledToolTip text={scoreTooltip(propertyName)}>
              <StyledScoreBorderContainer data-testid="StyledScoreBorderContainer">
                <StyledText text={formattedScore} />
              </StyledScoreBorderContainer>
            </StyledToolTip>
          )}
        </ScoreWrapper>
      </AnnotationAndScoreWrapper>
    </AnnotationAndScoreContainer>
  );
};
