import React from 'react';

import { StyledDividerContainer, StyledItemDivider, StyledName } from '../AnnotationAndScore.styles';

const constants = { outputLabel: 'Output' };

export const AnnotationAndScoreDivider = () => (
  <StyledDividerContainer>
    <StyledItemDivider />
    <StyledName text={constants.outputLabel} overflow="unset" type="bodyBold" />
    <StyledItemDivider />
  </StyledDividerContainer>
);
