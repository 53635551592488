import React, { useState } from 'react';

import { useRouter } from 'next/router';

import dayjs from 'dayjs';

import { AccountTreeOutlined } from '@mui/icons-material';

import { SummaryNoData } from './SummaryNoData/SummaryNoData';
import { SummaryOverview } from './SummaryOverview/SummaryOverview';
import { SummaryAnnotationDistribution } from './SummaryAnnotationDistribution/SummaryAnnotationDistribution';
import { InteractionTypeDropDown } from '@/components/shared/InteractionTypeDropDown/InteractionTypeDropDown';

import { StyledButton, StyledDoughnutChart, StyledRadio, StyledText } from '@/components/lib';
import {
  CardContainer,
  StyledTitleContainer,
  SummaryChartContainer,
  SummaryChartAndDistributionContainer,
  SummaryFiltersContainer
} from './Summary.styles';

import { routes } from '@/helpers/routes';
import { getPercentage } from '@/helpers/utils/getPercentage';
import { EnvType, useGetAppVersionProdSummary } from '@/helpers/services/api';

import { constants } from './summary.constants';
import { getUserPreferences, setStorageItem, storageKeys } from '@/helpers/utils/localStorage';

interface SummaryProps {
  envType: EnvType;
  selectedView: string;
  interactionType: string;
  selectedVersionId: number;
  setSelectedView: React.Dispatch<React.SetStateAction<string>>;
  setInteractionType: React.Dispatch<React.SetStateAction<string>>;
}

const {
  title,
  latency,
  sessions,
  radioOptions,
  latencyTooltip,
  interactions,
  userAnnotation,
  estAnnotation,
  changeViewBtnLabel
} = constants;

export const Summary = (props: SummaryProps) => {
  const { envType, selectedView, selectedVersionId, interactionType, setSelectedView, setInteractionType } = props;

  const { lightBar, darkMode, activeEnvs } = getUserPreferences();

  const [isChartView, setIsChartView] = useState(true);
  const [isIncludeEstimated, setIsIncludeEstimated] = useState(true);

  const { query, pathname, push, replace } = useRouter();
  const { data, isLoading } = useGetAppVersionProdSummary(selectedVersionId, { environment: envType });

  if (!data?.n_of_samples) {
    return <SummaryNoData isLoading={isLoading} />;
  }

  const curAnnotatedBadNum = isIncludeEstimated ? data?.annotated_bad : data?.user_annotated?.bad;
  const curAnnotatedGoodNum = isIncludeEstimated ? data?.annotated_good : data?.user_annotated?.good;
  const curAnnotatedNum = Number(curAnnotatedBadNum) + Number(curAnnotatedGoodNum);

  const allEstimatedAnnotatedNum = Number(data?.estimated?.bad) + Number(data?.estimated?.good);
  const allUserAnnotatedNum = Number(data?.user_annotated?.bad) + Number(data?.user_annotated?.good);

  const calculatedScore = Number(curAnnotatedGoodNum) / Number(curAnnotatedNum);
  const dataToDisplay = {
    estAnnotation: {
      label: estAnnotation,
      value: getPercentage(allEstimatedAnnotatedNum / Number(data?.n_of_samples), true)
    },
    userAnnotation: {
      label: userAnnotation,
      value: getPercentage(allUserAnnotatedNum / Number(data?.n_of_samples), true)
    },
    interactions: { label: interactions, value: data?.n_of_samples },
    latency: {
      label: latency,
      value: data?.latency_seconds ? `${data?.latency_seconds} Sec` : 'N/A',
      tooltip: latencyTooltip
    },
    sessions: { label: sessions, value: Number(data?.n_of_sessions) }
  };

  const dates =
    data?.start_date && data?.end_date
      ? `${dayjs(data?.start_date).format('lll')} - ${dayjs(data?.end_date).format('lll')}`
      : '';

  const chartData = [
    curAnnotatedBadNum || 0,
    curAnnotatedGoodNum || 0,
    isIncludeEstimated ? Number(data?.unannotated) : allEstimatedAnnotatedNum + Number(data?.unannotated)
  ];

  const handleCenterClick = () => setIsIncludeEstimated(!isIncludeEstimated);

  const handleRedirect = (annotation: string) => {
    annotation === 'unknown'
      ? push(`${routes.samples}?annotations=unknown${isIncludeEstimated ? '%3Bpending' : ''}`)
      : push(
          `${routes.samples}?annotations=${
            isIncludeEstimated ? `${annotation}%2Cestimated%3B` : ''
          }${annotation}%2Cuser_annotated`
        );
  };

  const handleChangeView = (state: string) => {
    setSelectedView(state);
    replace({ pathname, query: { ...query, sessions_view: state } });
    setStorageItem(storageKeys?.userPreferences, { lightBar, darkMode, activeEnvs, sessionsView: state });
  };

  return (
    <CardContainer data-testid="SummaryContainer">
      <SummaryChartAndDistributionContainer type="card">
        <StyledTitleContainer>
          <StyledText type="h3" contrast="low" text={title} />
          <StyledText type="tinyBold" text={dates} />
        </StyledTitleContainer>
        <SummaryFiltersContainer>
          <StyledRadio
            direction="row"
            state={selectedView}
            setState={handleChangeView}
            options={[
              {
                value: radioOptions.sessions?.value,
                label: radioOptions.sessions?.label,
                icon: <AccountTreeOutlined />
              },
              {
                value: radioOptions.types?.value,
                label: radioOptions.types?.label,
                icon: (
                  <InteractionTypeDropDown
                    interactionType={interactionType}
                    disabled={selectedView === radioOptions?.sessions?.value}
                    setInteractionType={setInteractionType}
                  />
                )
              }
            ]}
          />
        </SummaryFiltersContainer>
        <SummaryChartContainer>
          {isChartView ? (
            <StyledDoughnutChart
              data={chartData}
              score={calculatedScore}
              userAnnotated={data?.user_annotated}
              estimatedAnnotated={data?.estimated}
              isIncludeEstimated={isIncludeEstimated}
              pendingAnnotated={Number(data?.pending_annotations)}
              checkboxCheckedIcon="./assets/chart/select-all-checkbox.svg"
              checkboxUnCheckedIcon="./assets/chart/select-all-checkbox-unchecked.svg"
              handleCenterClick={handleCenterClick}
              actions={{
                badRedirect: () => handleRedirect('bad'),
                goodRedirect: () => handleRedirect('good'),
                unannotatedRedirect: () => handleRedirect('unknown')
              }}
            />
          ) : (
            <SummaryAnnotationDistribution summaryData={data} handleRedirect={handleRedirect} />
          )}
        </SummaryChartContainer>
        <StyledButton
          variant="outlined"
          data-testid="ChangeViewBtn"
          label={changeViewBtnLabel(isChartView)}
          onClick={() => setIsChartView(!isChartView)}
        />
      </SummaryChartAndDistributionContainer>
      <SummaryOverview dataToDisplay={dataToDisplay} isPartialDataMode={!data?.latency_seconds} />
    </CardContainer>
  );
};
