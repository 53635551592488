import { styled } from '@mui/material';
import { CloseRounded, OpenInFullRounded, RefreshRounded } from '@mui/icons-material';

import { StyledContainer, StyledDialogBase, StyledInput, StyledText } from '@/components/lib';

import { fadeInAnimation } from '@/helpers/animations';

interface TopicTextProps {
  asSection?: boolean;
}

export const TopicsContainer = styled(StyledContainer, {
  shouldForwardProp: prop => prop !== 'hide'
})<{ hide?: boolean }>(({ hide, theme }) => ({
  gap: '12px',
  height: 'auto',
  minHeight: '250px',
  padding: '16px 24px',

  [theme.breakpoints.down('lg')]: {
    display: hide ? 'none' : 'auto'
  }
}));

export const TopicsInnerContainer = styled(StyledContainer)(({ theme }) => ({
  gap: '12px',
  display: 'grid',
  overflow: 'auto',
  margin: '0 auto',
  maxWidth: '965px',
  alignItems: 'center',
  padding: '8px 0 12px',
  animation: `${fadeInAnimation} 0.5s ease`,
  gridTemplateColumns: 'repeat(auto-fill, minmax(170px, 2fr))',

  [theme.breakpoints.down('lg')]: { margin: '0' }
}));

export const TopicsHeaderContainer = styled(StyledContainer)({
  padding: '0',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  '.MuiBox-root': { marginLeft: 'auto' }
});

export const TopicsInput = styled(StyledInput)({
  width: '200px',
  margin: '0 8px 0 auto'
});

export const TopicsRefreshIcon = styled(RefreshRounded)(({ theme }) => ({
  cursor: 'pointer',
  fill: theme.palette.grey[400]
}));

export const TopicsExpandIcon = styled(OpenInFullRounded)(({ theme }) => ({
  fill: theme.palette.grey[400],
  cursor: 'pointer'
}));

export const TopicText = styled(StyledText, {
  shouldForwardProp: prop => prop !== 'asSection'
})<TopicTextProps>(({ asSection, theme }) => ({
  background: theme.palette.grey[400],
  borderRadius: '28px',
  color: theme.palette.common.white,
  fontSize: '16px',
  fontWeight: 800,
  padding: '4px 12px',
  whiteSpace: 'nowrap',
  overflow: 'visible',
  cursor: asSection ? 'pointer' : 'auto'
}));

export const TopicsDialogContainer = styled(StyledDialogBase)({
  '.MuiPaper-root': { width: '800px' },
  '.MuiTypography-h3': { marginTop: '100px' }
});

export const TopicsDialogCloseIcon = styled(CloseRounded)(({ theme }) => ({
  fill: theme.palette.grey[400],
  cursor: 'pointer'
}));

export const NoTopicsContainer = styled(StyledContainer)({
  margin: 'auto',
  gap: '4px',
  alignItems: 'center',
  justifyContent: 'center',
  animation: `${fadeInAnimation} 0.5s ease`,

  img: { marginBottom: '16px' }
});
