import React from 'react';

import { Stack } from '@mui/material';

import { StyledText } from '@/components/lib';
import {
  SummaryAnnotationDistributionBtnContainer,
  SummaryAnnotationDistributionContainer,
  SummaryAnnotationDistributionTitleContainer
} from './SummaryAnnotationDistribution.styles';

import { AnnotationType, ProductionDataSummarySchema } from '@/helpers/services/api';
import { getAnnotation } from '@/components/Samples/SamplesTable/SamplesTable.helpers';

import { constants } from '../summary.constants';

interface SummaryAnnotationDistributionProps {
  summaryData: ProductionDataSummarySchema | undefined;
  handleRedirect: (type: AnnotationType) => void;
}

const { estimated, userAnnotated } = constants.distribution;

export const SummaryAnnotationDistribution = ({ summaryData, handleRedirect }: SummaryAnnotationDistributionProps) => {
  const { annotations_distribution, annotated_good, annotated_bad, unannotated, n_of_samples } = summaryData || {};

  const calculatePercentage = (samples?: number) => {
    const percentage = (Number(samples) / Number(n_of_samples || 1)) * 100;

    return `${
      Number(samples) > 0 && percentage < 1 && percentage > 0 ? percentage.toFixed(2) : Math.round(percentage)
    }% (${samples})`;
  };

  const samplesCount = (type: AnnotationType) => {
    switch (type) {
      case AnnotationType['bad']:
        return annotated_bad || 0;
      case AnnotationType['good']:
        return annotated_good || 0;
      case AnnotationType['unknown']:
        return unannotated || 0;
      default:
        return 0;
    }
  };
  return (
    <SummaryAnnotationDistributionContainer>
      {[AnnotationType['bad'], AnnotationType['unknown'], AnnotationType['good']]?.map(annotation =>
        !samplesCount(annotation) ? (
          <></>
        ) : (
          <Stack key={annotation}>
            <SummaryAnnotationDistributionTitleContainer>
              {getAnnotation(AnnotationType[annotation], false)}
              <StyledText text={calculatePercentage(samplesCount(annotation) || 0)} type="small" />
            </SummaryAnnotationDistributionTitleContainer>
            {annotation === AnnotationType['good'] ? (
              <>
                {annotations_distribution?.good?.total_estimated ? (
                  <SummaryAnnotationDistributionBtnContainer onClick={() => handleRedirect(annotation)}>
                    <StyledText text={estimated} />
                    <StyledText text={annotations_distribution?.good?.total_estimated} type="bodyBold" />
                  </SummaryAnnotationDistributionBtnContainer>
                ) : (
                  <></>
                )}
                {annotations_distribution?.good?.total_user ? (
                  <SummaryAnnotationDistributionBtnContainer onClick={() => handleRedirect(annotation)}>
                    <StyledText text={userAnnotated} />
                    <StyledText text={annotations_distribution?.good?.total_user} type="bodyBold" />
                  </SummaryAnnotationDistributionBtnContainer>
                ) : (
                  <></>
                )}
              </>
            ) : (
              Array?.isArray(annotations_distribution?.[annotation]?.per_property) &&
              annotations_distribution?.[annotation]?.per_property?.map((v, i) => (
                <SummaryAnnotationDistributionBtnContainer key={i} onClick={() => handleRedirect(annotation)}>
                  <StyledText text={v?.name} />
                  <StyledText text={v?.count} type="bodyBold" />
                </SummaryAnnotationDistributionBtnContainer>
              ))
            )}
          </Stack>
        )
      )}
    </SummaryAnnotationDistributionContainer>
  );
};
