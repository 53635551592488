import { Divider, Stack, styled } from '@mui/material';

import { StyledButton, StyledText, StyledContainer } from '@/components/lib';

import { fadeInAnimation } from '@/helpers/animations';

export const AnnotationAndScoreContainer = styled(StyledContainer)<{ is_compare?: string }>(
  ({ theme, is_compare }) => ({
    gap: '8px',
    padding: is_compare ? '16px' : '24px 24px 16px 24px',
    border: `2px solid ${is_compare === 'true' ? theme.palette.primary.main : theme.palette.grey[200]}`,
    animation: `${fadeInAnimation} 0.5s ease`,
    minWidth: '300px',
    background: theme.palette.common.white
  })
);

export const StyledTextButton = styled(StyledButton)(({ theme }) => ({
  padding: 0,
  color: theme.palette.primary.main
}));

export const StyledResponseText = styled(StyledText)<{ is_compare?: string; is_drill_down?: string }>(
  ({ theme, is_compare, is_drill_down }) => ({
    fontWeight: 500,
    fontSize: '15px',
    maxWidth: is_compare ? '100%' : '20vw',
    minHeight: is_compare ? '81px' : 'auto',
    color: theme.palette.grey[600],
    ...(is_drill_down === 'true'
      ? {
          overflow: 'auto',
          maxHeight: '165px'
        }
      : {
          WebkitLineClamp: is_compare ? '4' : '7',
          display: '-webkit-box',
          textOverflow: 'ellipsis',
          WebkitBoxOrient: 'vertical',
          maxHeight: is_compare ? '81px' : '150px'
        })
  })
);

export const StyledInputText = styled(StyledText)<{ is_compare?: string }>(({ theme, is_compare }) => ({
  fontSize: '15px',
  fontWeight: 500,
  maxWidth: is_compare ? '100%' : '20vw',
  minHeight: is_compare ? '44px' : 'auto',
  maxHeight: is_compare ? '44px' : 'auto',
  overflow: 'hidden',
  WebkitLineClamp: '2',
  whiteSpace: 'initial',
  display: '-webkit-box',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  color: theme.palette.grey[600]
}));

export const AnnotationAndScoreWrapper = styled(StyledContainer)({
  padding: '0',
  marginTop: 'auto',
  flexDirection: 'row',
  alignItems: 'center',

  '.MuiSvgIcon-root': { cursor: 'pointer' }
});

export const StyledScoreBorderContainer = styled(StyledContainer)(({ theme }) => ({
  width: '44px',
  height: '48px',
  alignItems: 'center',
  borderRadius: '6px',
  border: `2px solid ${theme.palette.grey[200]}`,

  '.MuiTypography-root ': {
    fontWeight: 600,
    color: theme.palette.grey[400],
    whiteSpace: 'nowrap',
    width: '38px',
    textAlign: 'center'
  }
}));

export const StyledItemDivider = styled(Divider)(({ theme }) => ({
  height: '1px',
  background: theme.palette.grey[200],
  width: 'calc(50% - 40px)'
}));

export const StyledDividerContainer = styled(Stack)({
  width: '100%',
  padding: '16px 0',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
});

export const StyledName = styled(StyledText)(({ theme }) => ({
  color: theme.palette.grey[400]
}));

export const ScoreWrapper = styled(Stack)({
  marginLeft: 'auto',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px'
});

export const AnnotationCellWrapper = styled(Stack)<{ is_compare?: string }>(({ theme, is_compare }) => ({
  justifyContent: 'center',
  width: '48px',
  padding: '0 8px',
  borderRadius: '8px',
  border: is_compare === 'true' ? `2px solid ${theme.palette.grey[200]}` : 'none'
}));
