import React, { useEffect, useState } from 'react';

import { Button, useTheme } from '@mui/material';
import { UndoRounded } from '@mui/icons-material';

import { AnalysisInsight } from './AnalysisInsight/AnalysisInsight';
import { WeakSegmentContent } from './WeakSegmentContent/WeakSegmentContent';

import { StyledText } from '@/components/lib';
import {
  InsightCardContainer,
  InsightClearIcon,
  InsightInnerContainer,
  InsightUndoContainer,
  InsightsScoreContainer,
  InsightsTitleContainer,
  ScoreSampleContainer
} from '../Insights.styles';

import { InsightSchema, updateInsight } from '@/helpers/services/api';

import { constants } from '../../overview.constants';

interface InsightProps {
  insight: InsightSchema;
  refetch: () => void;
}

const { dismissed, samples, undoButtonLabel } = constants.insights;

export const Insight = ({ insight, refetch }: InsightProps) => {
  const [undo, setUndo] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [showLess, setShowLess] = useState(true);

  const { palette } = useTheme();

  const isPropertySegment = insight?.insight_type === 'Property Segment';

  const handleDismiss = () => setUndo(true);
  const handleStartHover = () => setHovered(true);
  const handleStopHover = () => setHovered(false);
  const handleNullValue = (arg: number) => (arg ? `${arg}` : 'N/A');

  const handleUndoDismiss = async () => {
    setUndo(false);

    await updateInsight(insight?.id, { is_deleted: false });
  };

  useEffect(() => {
    if (undo) {
      updateInsight(insight?.id, { is_deleted: true });

      const timeoutDeletion = setTimeout(() => {
        refetch();
        setUndo(false);
      }, 4000);

      return () => clearTimeout(timeoutDeletion);
    }
  }, [undo, insight, refetch]);

  return (
    <InsightCardContainer
      onMouseOver={handleStartHover}
      onMouseLeave={handleStopHover}
      onClick={() => setShowLess(!showLess)}
    >
      {undo ? (
        <InsightUndoContainer>
          <StyledText text={dismissed} />
          <Button variant="text" startIcon={<UndoRounded />} onClick={handleUndoDismiss}>
            {undoButtonLabel}
          </Button>
        </InsightUndoContainer>
      ) : (
        <>
          <InsightInnerContainer>
            <InsightsTitleContainer>
              <StyledText
                type="bodyBold"
                text={insight?.name || insight?.insight_type}
                color={hovered ? palette.grey[600] : palette.grey[400]}
              />
            </InsightsTitleContainer>
            <InsightsScoreContainer>
              <ScoreSampleContainer>
                <StyledText text={handleNullValue(insight?.n_of_samples)} type="bodyBold" />
                <StyledText text={samples} type="small" />
              </ScoreSampleContainer>
              <InsightClearIcon onClick={handleDismiss} hovered={hovered ? 'true' : 'false'} />
            </InsightsScoreContainer>
          </InsightInnerContainer>
          {isPropertySegment ? (
            <AnalysisInsight insight={insight} showLess={showLess} />
          ) : (
            <WeakSegmentContent insight={insight} />
          )}
        </>
      )}
    </InsightCardContainer>
  );
};
