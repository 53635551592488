import { Stack, styled } from '@mui/material';

import { StyledButton } from '@/components/lib';

export const StyledContainer = styled(Stack)({
  padding: '0',
  margin: '-18px 0',
  minHeight: '400px',
  borderRadius: '16px'
});

interface StyledContentContainerProps {
  categorical?: boolean;
}

export const StyledContentContainer = styled(Stack, {
  shouldForwardProp: prop => prop !== 'categorical'
})<StyledContentContainerProps>(({ categorical, theme }) => ({
  display: 'grid',
  gridTemplateColumns: categorical ? '360px auto' : '430px auto',
  height: '100%',
  maxHeight: 'calc(100% - 112px)',

  '@media (max-width: 850px)': {
    overflow: 'auto',

    '&::-webkit-scrollbar': {
      height: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[300],
      borderRadius: '8px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.grey[500]
    }
  }
}));

export const StyledSeeAllSamplesButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: '16px 4px',
  marginLeft: 'auto'
}));
