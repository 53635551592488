import { Stack, styled } from '@mui/material';

import { StyledContainer } from '@/components/lib';

export const SummaryAnnotationDistributionContainer = styled(StyledContainer)(({ theme }) => ({
  gap: '24px',
  height: '260px',
  minWidth: '250px',
  overflowY: 'auto',

  '.MuiTypography-h5': { color: theme.palette.grey[400] }
}));

export const SummaryAnnotationDistributionTitleContainer = styled(Stack)({
  gap: '8px',
  margin: '4px 0',
  flexDirection: 'row',
  alignItems: 'center'
});

export const SummaryAnnotationDistributionBtnContainer = styled(Stack)(({ theme }) => ({
  gap: '16px',
  margin: '4px 0',
  cursor: 'pointer',
  transition: '0.2s',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `1px solid transparent`,

  ':hover': {
    borderColor: theme.palette.primary.main,

    '.MuiTypography-root': { color: theme.palette.primary.main, fontWeight: 700, letterSpacing: '-0.3px' }
  },

  '.MuiTypography-body2': { color: theme.palette.grey[500], overflow: 'visible' }
}));
