import { styled } from '@mui/material';
import { MoreHorizRounded } from '@mui/icons-material';

import { StyledContainer, StyledText } from '@/components/lib';

import { CategoryPropertyValue } from '@/helpers/services/api';

export const PropertyContainer = styled(StyledContainer, {
  shouldForwardProp: prop => prop !== 'score'
})<{ score?: number | CategoryPropertyValue[] | undefined }>(({ score, theme }) => ({
  height: '100px',
  cursor: 'pointer',
  maxWidth: '400px',
  transition: '0.5s',
  flexDirection: 'row',
  padding: '12px 16px',
  alignItems: 'center',
  opacity: score === undefined ? 0.6 : 1,
  border: `2px solid ${theme.palette.grey[200]}`,

  ':hover': {
    background: theme.palette.grey[100]
  },

  [theme.breakpoints.down('xl')]: {
    height: '90px',
    maxWidth: '300px'
  }
}));

export const PropertyContentContainer = styled(StyledContainer)(({ theme }) => ({
  padding: '0',

  '.MuiTypography-h3': {
    whiteSpace: 'nowrap',
    color: theme.palette.grey[600]
  }
}));

export const PropertyCategoriesContainer = styled(StyledContainer)(() => ({
  padding: '0',
  flexDirection: 'row'
}));

export const PropertyContentInnerContainer = styled(StyledContainer)(({ theme }) => ({
  height: 'auto',
  padding: '16px 0',
  flexDirection: 'row',
  justifyContent: 'space-between',
  maxWidth: 'calc(100% - 60px)',

  [theme.breakpoints.down('xl')]: {
    padding: '18px 0'
  }
}));

export const PropertyActionsContainer = styled(StyledContainer, {
  shouldForwardProp: prop => prop !== 'svg_color'
})<{ svg_color?: string }>(({ svg_color }) => ({
  padding: 0,
  width: '50px',
  height: '100%',
  justifyContent: 'space-between',

  '.MuiSvgIcon-root': { color: svg_color, fontSize: '20px', margin: '0 0 0 32px' }
}));

export const PropertyOtherPropContainer = styled(StyledContainer)(() => ({
  width: '100%',
  flexDirection: 'row',
  padding: 0
}));

export const PropertyScoreColor = styled(StyledContainer)(() => ({
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  margin: '8px 4px 0 auto',
  padding: 0
}));

export const PropertyMenuIcon = styled(MoreHorizRounded)(({ theme }) => ({
  marginLeft: 'auto',
  cursor: 'pointer',
  color: theme.palette.grey[400]
}));

export const PropertyScoreText = styled(StyledText)(({ theme }) => ({
  fontSize: '28px',
  lineHeight: '30px',
  fontWeight: 500,
  marginTop: '8px',

  [theme.breakpoints.down('xl')]: {
    fontSize: '20px',
    lineHeight: '24px'
  }
}));

export const PropertyCategoryPercentage = styled(StyledText, {
  shouldForwardProp: prop => prop !== 'percentage'
})<{ percentage: number }>(({ percentage, theme }) => ({
  width: '75px',
  height: '40px',
  padding: '6px',
  fontWeight: 700,
  fontSize: '10px',
  lineHeight: '16px',
  borderRadius: '6px',
  color: theme.palette.grey[400],
  background: `linear-gradient(to right, ${theme.palette.grey[200]} ${percentage * 100}%, ${theme.palette.grey[100]} ${
    percentage * 100
  }%)`,

  ':first-line': { fontSize: '14px', color: theme.palette.grey[600] }
}));
