import { Stack, styled, alpha } from '@mui/material';

import { StyledContainer, StyledText } from '@/components/lib';

import { fadeInAnimation } from '@/helpers/animations';

import noDataImg from '@/public/assets/chart/no-results-in-range.svg';

export const OverviewNoVersionSelectedContainer = styled(StyledContainer)({
  width: '100%',
  height: '100%',
  margin: 'auto',
  marginTop: '27vh',
  alignItems: 'center',
  justifyContent: 'center',
  animation: `${fadeInAnimation} 0.5s ease`,
  gap: 0
});

export const OverviewNoVersionSelectedButton = styled(StyledText)(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'inline',
  marginLeft: '4px',
  textDecoration: 'underline',
  cursor: 'pointer'
}));

export const StyledImageContainer = styled(Stack)(({ theme }) => ({
  background: alpha(theme.palette.primary.main, 0.2),
  marginBottom: '32px',
  width: '80px',
  height: '80px',
  borderRadius: '48px',
  justifyContent: 'center',
  alignItems: 'center',

  '& svg': {
    height: '48px',
    width: '48px'
  }
}));

export const StyledTitleText = styled(StyledText)(({ theme }) => ({
  color: theme.palette.grey[400]
}));

export const StyledSubTitleText = styled(StyledText)(({ theme }) => ({
  marginTop: '4px',
  maxWidth: '250px',
  textAlign: 'center',
  color: theme.palette.grey[300]
}));

export const InsightsEmptyContainer = styled(StyledContainer)(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: '40px auto',

  [theme.breakpoints.down('xl')]: { display: 'none' }
}));

export const AnnotationOvertimeGraphContainer = styled(StyledContainer)({
  minHeight: '535px',

  '.MuiTypography-h2': { margin: '12px' },

  canvas: { animation: `${fadeInAnimation} 0.5s ease` }
});

export const AnnotationOvertimeGraphHeaderContainer = styled(Stack)(({ theme }) => ({
  margin: '0 16px 0 0',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  '.MuiTypography-h5': { span: { color: theme.palette.primary.main } }
}));

export const OvertimeGraphNoDataImage = styled(Stack)({
  zIndex: 2,
  width: '298px',
  height: '184px',
  alignSelf: 'center',
  background: '#ffffff',
  backgroundSize: 'cover',
  margin: '70px auto -250px',
  backgroundPosition: 'center',
  backgroundImage: `url(${noDataImg.src})`,
  animation: `${fadeInAnimation} 0.5s ease`
});
