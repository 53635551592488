import React, { useState } from 'react';

import { NoDataAvailable } from '@/components/shared/NoDataAvailable/NoDataAvailable';

import { StyledPercentageContainer, StyledText } from '@/components/lib';
import {
  TopicsContainer,
  TopicsDialogCloseIcon,
  TopicsDialogContainer,
  TopicsHeaderContainer,
  TopicsInnerContainer,
  TopicsInput
} from '../Topics.styles';

import { AppVersionTopicSchema } from '@/helpers/services/api';

import { constants } from '../../overview.constants';

interface TopicsDialogProps {
  open: boolean;
  topics?: AppVersionTopicSchema[];
  topicsCount: number;
  onClose: () => void;
  handleRedirect: (topic: string) => void;
}

export const TopicsDialog = ({ open, topics, topicsCount, onClose, handleRedirect }: TopicsDialogProps) => {
  const [input, setInput] = useState('');

  const filteredTopics = input
    ? topics?.filter(topic => topic?.topic_name?.toLowerCase().includes(input.toLowerCase()))
    : topics;

  return (
    <TopicsDialogContainer open={open} onClose={onClose}>
      <TopicsContainer>
        <TopicsHeaderContainer>
          <StyledText text={constants.topics.title} type="h2" />
          <TopicsInput
            value={input}
            onChange={e => setInput(e.target.value)}
            onCloseIconClick={() => setInput('')}
            placeholder={constants?.topics?.inputPlaceholder}
            searchField
          />
          <TopicsDialogCloseIcon onClick={onClose} />
        </TopicsHeaderContainer>
        {filteredTopics && filteredTopics[0] ? (
          <TopicsInnerContainer>
            {filteredTopics?.map((topic: AppVersionTopicSchema, i: number) => (
              <StyledPercentageContainer
                key={i}
                percentage={Number(topic?.count / topicsCount)}
                amount={topic?.count}
                title={topic?.topic_name}
                onClick={() => handleRedirect(topic?.topic_name)}
              />
            ))}
          </TopicsInnerContainer>
        ) : (
          <NoDataAvailable />
        )}
      </TopicsContainer>
    </TopicsDialogContainer>
  );
};
