import React from 'react';

import { StyledDistributionChart, StyledSkeleton } from '@/components/lib';

import {
  ReadPropertyConfigSchema,
  EnvType,
  GetPropertyHistogramParams,
  ListPropertiesDataApiV1ApplicationVerionsAppVersionIdPropertiesConfigDataGet200,
  useGetPropertyHistogram
} from '@/helpers/services/api';

import { constants } from '../propertyDrillDown.constants';

interface GetPropertyHistogramData {
  bin_edges: number[];
  hist: number[];
  greater_is_better: boolean | null;
}

interface PropertyDrillDownDistributionProps {
  currentProperty: ReadPropertyConfigSchema;
  headerData: ListPropertiesDataApiV1ApplicationVerionsAppVersionIdPropertiesConfigDataGet200 | undefined;
  selectedEnv: string;
  versionId: number;
}

const { title, subtitle, yAxisName } = constants.chart;

export const PropertyDrillDownDistribution = (props: PropertyDrillDownDistributionProps) => {
  const { currentProperty, headerData, selectedEnv, versionId } = props;

  const params: GetPropertyHistogramParams = {
    property_name: currentProperty.text_property,
    property_type: currentProperty.kind
  };

  const { data, isLoading }: { data?: GetPropertyHistogramData; isLoading: boolean } = useGetPropertyHistogram(
    versionId,
    selectedEnv as EnvType,
    params
  );
  const { threshold, id } = currentProperty;

  const value = headerData && headerData[selectedEnv]?.[id];
  const average = typeof value === 'number' ? +value?.toFixed(2) : 0;

  const labelNamesLength = data?.bin_edges?.length;
  const isNeedToLeftMoreNumbers = labelNamesLength && +data.bin_edges[labelNamesLength - 1]?.toFixed(3) === 0;
  const curLabels = Array.isArray(data?.bin_edges)
    ? data?.bin_edges?.map((el: number) => (isNeedToLeftMoreNumbers ? +el.toFixed(5) : +el.toFixed(3))) || []
    : [];

  const curData = data?.hist || [];
  const curAverage = curLabels.findIndex(el => average < el);
  const curCondition = curLabels.findIndex(el => Number(threshold) < el);

  if (isLoading) {
    return <StyledSkeleton height="100%" width="410px" />;
  }

  return (
    <StyledDistributionChart
      title={title}
      width={410}
      height={320}
      data={curData}
      labels={curLabels}
      subtitle={subtitle}
      yAxisName={yAxisName}
      condition={curCondition}
      isGoodAndBad={data?.greater_is_better}
      average={{ value: curAverage, direction: 'vertical' }}
      data-testid="PropertyDrillDownDistribution"
    />
  );
};
