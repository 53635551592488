import React from 'react';

import { StackProps } from '@mui/material';

import { StyledSkeleton } from '@/components/lib';
import { NoDataAvailable } from '@/components/shared/NoDataAvailable/NoDataAvailable';
import { AnnotationAndScore } from '@/components/shared/AnnotationAndScore/AnnotationAndScore';

import { StyledContainer, StyledDivider, StyledInteractionsContainer } from './PropertyDrillDownInteractions.styles';

import { TextScore } from '@/helpers/services/api';

export interface PropertyDrillDownInteractionsProps extends StackProps {
  name: string;
  isFetching: boolean;
  isCategorical: boolean;
  data: TextScore[] | undefined;
  handleOpenDialog: (interactionId: string) => void;
}

export const constants = { skeleton: { height: '100%', width: '50%' } };

export const PropertyDrillDownInteractions = (props: PropertyDrillDownInteractionsProps) => {
  const { name, data, isFetching, isCategorical, handleOpenDialog } = props;

  const showDivider = isCategorical && (isFetching || !data?.length);

  return (
    <StyledContainer data-testid="HighestLowestSamplesContainer">
      {showDivider && <StyledDivider />}
      {isFetching ? (
        [1, 2]?.map(i => <StyledSkeleton key={i} height={constants.skeleton.height} width={constants.skeleton.width} />)
      ) : Array.isArray(data) && data?.length ? (
        <StyledInteractionsContainer>
          {data?.map(e => (
            <AnnotationAndScore
              key={e.user_interaction_id}
              sample={e}
              propertyName={name}
              handleOpenDialog={handleOpenDialog}
              isDrillDown
            />
          ))}
        </StyledInteractionsContainer>
      ) : (
        <NoDataAvailable />
      )}
    </StyledContainer>
  );
};
