import React from 'react';

import { StyledSkeleton, StyledText } from '@/components/lib';

import { OverviewNoDataAvailable } from '../../OverviewNoDataAvailable/OverviewNoDataAvailable';

import { CardContainer, CardDescriptionContainer, SummaryChartAndDistributionContainer } from '../Summary.styles';

interface AnnotationsNoDataProps {
  isLoading: boolean;
}

const constants = {
  title: 'Score',
  skeletonHeight: '418px'
};

const { skeletonHeight, title } = constants;

export const SummaryNoData = ({ isLoading }: AnnotationsNoDataProps) => {
  if (isLoading) {
    return (
      <CardContainer>
        <StyledSkeleton height={skeletonHeight} />
        <StyledSkeleton height={skeletonHeight} />;
      </CardContainer>
    );
  }

  return (
    <CardContainer>
      <SummaryChartAndDistributionContainer type="card">
        <StyledText text={title} type="h3" contrast="low" />
        <OverviewNoDataAvailable />
      </SummaryChartAndDistributionContainer>
      <CardDescriptionContainer type="card" />
    </CardContainer>
  );
};
