import { Stack, styled, alpha } from '@mui/material';

import { StyledContainer, StyledText } from '@/components/lib';

import { fadeInAnimation } from '@/helpers/animations';

export const OverviewNoDataAvailableContainer = styled(StyledContainer)({
  width: '100%',
  height: '100%',
  margin: 'auto',
  alignItems: 'center',
  justifyContent: 'center',
  animation: `${fadeInAnimation} 0.5s ease`,
  gap: 0
});

export const OverviewNoDataAvailableButton = styled(StyledText)(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'inline',
  marginLeft: '4px',
  textDecoration: 'underline',
  cursor: 'pointer'
}));

export const StyledImageContainer = styled(Stack)<{ type?: string }>(({ theme, type }) => ({
  background:
    type === 'Insights'
      ? '#02A5E633'
      : type === 'Topics'
        ? 'rgba(54, 123, 244, 0.2)'
        : type === 'Properties'
          ? 'rgba(0, 178, 194, 0.2)'
          : alpha(theme.palette.primary.main, 0.2),
  marginBottom: '32px',
  width: '80px',
  height: '80px',
  borderRadius: '48px',
  justifyContent: 'center',
  alignItems: 'center',

  '& svg': {
    width: '48px',
    height: '48px',
    color:
      type === 'Insights'
        ? '#02A5E6'
        : type === 'Topics'
          ? '#367BF4'
          : type === 'Properties'
            ? '#00B2C2'
            : theme.palette.primary.main
  }
}));

export const StyledTitleText = styled(StyledText)(({ theme }) => ({
  color: theme.palette.grey[400],
  marginBottom: '4px'
}));

export const StyledSubTitleText = styled(StyledText)(({ theme }) => ({
  color: theme.palette.grey[300],
  maxWidth: '250px',
  textAlign: 'center'
}));

export const StyledDescriptionText = styled(StyledText)(({ theme }) => ({
  color: theme.palette.grey[300],
  maxWidth: '450px',
  textAlign: 'center'
}));
