import React, { useEffect, useState } from 'react';

import { StyledDialog, StyledInput } from '@/components/lib';

import { AppVersionTopicSchema } from '@/helpers/services/api';

import { constants } from '../../overview.constants';

interface TopicsEditDialogProps {
  open: boolean;
  topic: AppVersionTopicSchema;
  refetchTopics: () => void;
  closeDialog: () => void;
}

const { name, description, submitButtonLabel, title } = constants.topics.edit;

export const TopicsEditDialog = ({ open, topic, closeDialog, refetchTopics }: TopicsEditDialogProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [input, setInput] = useState<AppVersionTopicSchema>(topic);

  const handleSave = async () => {
    setIsLoading(true);
    // Todo - Implement here the topic edit endpoint and then the following
    await refetchTopics();
    closeDialog();
    setIsLoading(false);
  };

  const handleInputChange = (text: string, key: 'topic_name' | 'description') => {
    input && setInput({ ...input, [key]: text });
  };

  useEffect(() => setInput(topic), [topic]);

  return (
    <StyledDialog
      open={open}
      title={title}
      isLoading={isLoading}
      closeDialog={closeDialog}
      submitButtonAction={handleSave}
      submitButtonLabel={submitButtonLabel}
    >
      <StyledInput
        label={name}
        value={input?.topic_name as string}
        onChange={e => handleInputChange(e?.target?.value, 'topic_name')}
      />
      <StyledInput
        label={description}
        value={input?.description as string}
        onChange={e => handleInputChange(e?.target?.value, 'description')}
      />
    </StyledDialog>
  );
};
