import React from 'react';

import Image from 'next/image';

import { Stack } from '@mui/material';

import { StyledText } from '@/components/lib';
import { NewInsightsArrowUp, NewInsightsCardContainer } from '../Insights.styles';

import sandClockImg from '@/public/assets/overview/sand-clock.svg';

import { constants } from '../../overview.constants';

const { description, title, imgAlt } = constants.insights.recalculateAvailable;

export const NewInsightsAvailable = () => (
  <NewInsightsCardContainer>
    <Image src={sandClockImg} alt={imgAlt} width={36} height={36} />
    <Stack>
      <StyledText text={title} type="h3" />
      <StyledText text={description} />
    </Stack>
    <NewInsightsArrowUp />
  </NewInsightsCardContainer>
);
