import { Button, styled } from '@mui/material';
import { ClearRounded, SubdirectoryArrowRight } from '@mui/icons-material';

import { StyledButton, StyledContainer, StyledText } from '@/components/lib';

export const InsightsContainer = styled(StyledContainer)(({ theme }) => ({
  padding: '0',
  margin: '0 16px',
  height: 'calc(100vh - 216px)',

  [theme.breakpoints.down('xl')]: {
    height: 'auto',
    margin: '0'
  }
}));

export const InsightsScrollContainer = styled(StyledContainer)(({ theme }) => ({
  gap: '24px',
  padding: '0',
  width: 'auto',
  borderRadius: '0',
  height: 'calc(100vh - 216px)',

  [theme.breakpoints.down('xl')]: {
    flexDirection: 'row',
    overflow: 'auto',
    height: 'auto',
    padding: '0 0 16px',
    margin: '0 0 -16px'
  }
}));

export const InsightsTitleContainer = styled(StyledContainer)({
  padding: 0,
  maxWidth: '200px'
});

export const InsightsScoreContainer = styled(StyledContainer)({
  padding: 0,
  width: 'auto',
  alignSelf: 'start',
  flexDirection: 'row',
  alignItems: 'center',
  margin: ' 0 -12px 0 auto'
});

export const InsightsTagsContainer = styled(StyledContainer)({
  gap: '16px',
  flexWrap: 'wrap',
  padding: '12px 0',
  flexDirection: 'row',
  alignItems: 'center'
});

export const InsightTag = styled(StyledText, {
  shouldForwardProp: prop => prop !== 'isTopic'
})<{ isTopic?: boolean }>(({ isTopic, theme }) => ({
  padding: '8px',
  fontWeight: 700,
  borderRadius: '8px',
  color: theme.palette.grey[500],
  background: isTopic ? theme.palette.grey[200] : theme.palette.common.white
}));

export const InsightClearIcon = styled(ClearRounded, {
  shouldForwardProp: prop => prop !== 'hovered'
})<{ hovered?: string }>(({ hovered, theme }) => ({
  cursor: 'pointer',
  fontWeight: 700,
  margin: '0 0 0 -6px !important',
  color: hovered === 'true' ? theme.palette.grey[500] : 'transparent'
}));

export const InsightCardContainer = styled(StyledContainer)(({ theme }) => ({
  gap: '2px',
  padding: '16px',
  maxWidth: '600px',
  minWidth: '350px',
  border: `2px solid ${theme.palette.grey[200]}`,

  ':hover': {
    background: theme.palette.grey[200]
  }
}));

export const InsightAnalysisContainer = styled(StyledContainer)(({ theme }) => ({
  height: '100%',
  cursor: 'pointer',
  padding: '8px 0 0',

  '.MuiTypography-subtitle2': { color: theme.palette.grey[500] }
}));

export const InsightInnerContainer = styled(StyledContainer)({
  padding: 0,
  flexDirection: 'row',
  alignItems: 'center',

  '.MuiButtonBase-root, .MuiSvgIcon-root': { padding: 0, marginTop: '6px' }
});

export const InsightUndoContainer = styled(StyledContainer)({
  margin: 'auto',
  padding: '8px 0 0',
  alignItems: 'center',
  justifyContent: 'center'
});

export const ScoreSampleContainer = styled(StyledContainer)({
  alignItems: 'center',
  flexDirection: 'row',
  padding: 0,
  margin: '3px 0 3px 10px',
  gap: '4px'
});

export const InsightViewButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'analysis'
})<{ analysis?: boolean }>(({ analysis }) => ({
  fontSize: '14px',
  padding: '8px 12px 0',
  margin: analysis ? '4px 4px 0 auto' : '0',
  width: analysis ? 'fit-content' : '220px'
}));

export const NoInsightContainer = styled(StyledContainer)(({ theme }) => ({
  width: 'auto',
  height: '100%',
  padding: '24px',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  maxHeight: 'calc(100vh - 234px)',
  border: `2px solid ${theme.palette.grey[200]}`,

  [theme.breakpoints.down('xl')]: { display: 'none' }
}));

export const InsightsRefreshButton = styled(StyledButton)(({ theme }) => ({
  cursor: 'pointer',
  marginLeft: 'auto',
  borderRadius: '8px',
  color: theme.palette.grey[500],
  background: theme.palette.grey[200],

  ':hover': { background: theme.palette.grey[300] }
}));

export const InsightsHeaderContainer = styled(StyledContainer)({
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0 4px 8px'
});

export const NewInsightsCardContainer = styled(StyledContainer)(({ theme }) => ({
  gap: '16px',
  width: '100%',
  padding: '16px',
  minWidth: '350px',
  alignItems: 'center',
  flexDirection: 'row',
  border: `2px solid ${theme.palette.grey[200]}`,

  [theme.breakpoints.down('xl')]: { display: 'none' }
}));

export const NewInsightsArrowUp = styled(SubdirectoryArrowRight)({
  rotate: '270deg'
});
