import React, { useState } from 'react';

import { useRouter } from 'next/router';

import { useMediaQuery, useTheme } from '@mui/material';

import { Topics } from './Topics/Topics';
import { Summary } from './Summary/Summary';
import { Insights } from './Insights/Insights';
import { Properties } from './Properties/Properties';
import { AppFilters } from '../shared/AppFilters/AppFilters';
import { AnnotationOvertimeGraph } from './AnnotationOvertimeGraph/AnnotationOvertimeGraph';
import { OverviewNoVersionSelected } from './OverviewNoVersionSelected/OverviewNoVersionSelected';

import { StyledContainer } from '@/components/lib';
import { OverviewInnerGrid, OverViewPropertiesContainer, SummaryTopicsContainer } from './Overview.styles';

import { EnvType } from '@/helpers/services/api';
import { getAppStorage, getUserPreferences } from '@/helpers/utils/localStorage';

import { constants } from './Summary/summary.constants';

export const Overview = () => {
  const { query } = useRouter();

  const { sessionsView } = getUserPreferences();
  const { interactionType: interactionTypeStorage } = getAppStorage();

  const [selectedView, setSelectedView] = useState(
    (query?.sessions_view as string) || sessionsView || constants?.radioOptions?.sessions?.value
  );

  const [refreshValue, setRefreshValue] = useState(false);
  const [interactionType, setInteractionType] = useState(interactionTypeStorage?.value ?? '');
  const [selectedApp, setSelectedApp] = useState({
    appId: 0,
    versionId: 0,
    type: 'EVAL',
    timestamp: { start: NaN, end: NaN }
  });

  const { breakpoints } = useTheme();

  const isDesktop = useMediaQuery(breakpoints.up('xl'));

  const selectedAppId = selectedApp?.appId;
  const envType = selectedApp?.type as EnvType;
  const selectedVersionId = selectedApp?.versionId;

  const isProd = envType === EnvType['PROD'];
  const isPenTest = envType === EnvType['PENTEST'];
  const isAllInteractionTypes = isProd || selectedView === constants?.radioOptions?.sessions?.value;

  const refresh = () => {
    const appCopy = { ...selectedApp };

    setRefreshValue(!refreshValue);
    setSelectedApp({ appId: NaN, versionId: NaN, type: EnvType['EVAL'], timestamp: { start: NaN, end: NaN } });

    setTimeout(() => setSelectedApp(appCopy), 100);
  };

  return (
    <StyledContainer>
      <AppFilters
        isOverview={true}
        initialLoading={true}
        refreshValue={refreshValue}
        interactionType={interactionType}
        refresh={refresh}
        setSelectedApp={setSelectedApp}
        setInteractionType={setInteractionType}
      />
      {!selectedAppId ? null : selectedVersionId ? (
        <OverviewInnerGrid pentest={isPenTest}>
          <OverViewPropertiesContainer data-testid="OverViewPropertiesContainer">
            {isProd ? (
              <AnnotationOvertimeGraph
                envType={envType}
                timestamp={selectedApp.timestamp}
                selectedVersionId={selectedVersionId}
              />
            ) : (
              <SummaryTopicsContainer>
                <Summary
                  envType={envType}
                  selectedView={selectedView}
                  interactionType={interactionType}
                  selectedVersionId={selectedVersionId}
                  setSelectedView={setSelectedView}
                  setInteractionType={setInteractionType}
                />
                <Topics envType={envType} selectedVersionId={selectedVersionId} />
              </SummaryTopicsContainer>
            )}
            {!isDesktop && (
              <Insights envType={envType} selectedVersionId={selectedVersionId} interactionType={interactionType} />
            )}
            <Properties
              envType={envType}
              selectedAppId={selectedAppId}
              interactionType={interactionType}
              timestamp={selectedApp?.timestamp}
              selectedVersionId={selectedVersionId}
              isAllInteractionTypes={isAllInteractionTypes}
            />
          </OverViewPropertiesContainer>
          {isProd ? (
            <Topics envType={envType} timestamp={selectedApp.timestamp} selectedVersionId={selectedVersionId} />
          ) : isDesktop ? (
            <Insights envType={envType} selectedVersionId={selectedVersionId} interactionType={interactionType} />
          ) : (
            <></>
          )}
        </OverviewInnerGrid>
      ) : (
        <OverviewNoVersionSelected isPenTest={isPenTest} />
      )}
    </StyledContainer>
  );
};
