import React from 'react';

import Head from 'next/head';

import { routesMetaData } from '@/helpers/routes';

import { Overview } from '@/components/Overview/Overview';

const OverviewPage = () => (
  <>
    <Head>
      <title>{routesMetaData.overview}</title>
    </Head>
    <Overview />
  </>
);

export default OverviewPage;
