import React from 'react';

import { StyledText } from '@/components/lib';
import {
  PropertyCategoriesContainer,
  PropertyCategoryPercentage,
  PropertyContentContainer,
  PropertyScoreText
} from '../Property.styles';

import { CategoryPropertyValue } from '@/helpers/services/api';

import { ReadPropertyConfigSchemaExtended } from '../Property';

interface PropertyContentProps {
  score: number | undefined | CategoryPropertyValue[];
  scoreDynamicColor: string;
  propertyData: ReadPropertyConfigSchemaExtended;
}

const constants = {
  categoricalTitle: (name: string, numberOfCategories: number) => `${name} | ${numberOfCategories}`,
  categoryLabel: (category: string | null | undefined, percentage: number) =>
    `${String(category).slice(0, 7) + `${Number(category?.length) > 7 ? '...' : ''}`}\n${Math.round(percentage * 100)}%`
};

export const PropertyContent = ({ propertyData, score, scoreDynamicColor }: PropertyContentProps) => {
  const isCategorical = typeof score === 'object';

  if (isCategorical) {
    const numberOfCategories = score?.length;
    const totalCount = Array?.isArray(score)
      ? score?.map(e => e.count)?.reduce((total, value) => total + Number(value), 0)
      : 0;

    return (
      <PropertyContentContainer>
        <StyledText text={constants.categoricalTitle(propertyData?.text_property, numberOfCategories)} type="h3" />
        <PropertyCategoriesContainer>
          {Array.isArray(score) &&
            score
              .slice(0, 2)
              .map(category => (
                <PropertyCategoryPercentage
                  key={category.name}
                  percentage={category.count / totalCount}
                  text={constants.categoryLabel(category.name, category.count / totalCount)}
                />
              ))}
        </PropertyCategoriesContainer>
      </PropertyContentContainer>
    );
  }

  const scoreToDisplay = !!score || score === 0 ? Number(score) : 'N/A';

  return (
    <PropertyContentContainer>
      <StyledText type="h3" text={propertyData?.text_property} />
      <PropertyScoreText text={scoreToDisplay} color={scoreDynamicColor} />
    </PropertyContentContainer>
  );
};
