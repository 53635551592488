import React from 'react';

import { useRouter } from 'next/router';

import { useTheme } from '@mui/material';

import { StyledText } from '@/components/lib';
import {
  InsightInnerContainer,
  InsightsTagsContainer,
  InsightTag,
  InsightViewButton,
  ScoreSampleContainer
} from '../../Insights.styles';

import { routes } from '@/helpers/routes';
import { getPercentage } from '@/helpers/utils/getPercentage';
import { DataFilter, InsightSchema } from '@/helpers/services/api';
import { buildDrillDownQuery, topicsInsightString } from '../insight.helpers';
import { calculateDataFilterString } from '@/helpers/utils/calculateDataFilterString';

import { constants } from '../../../overview.constants';

interface WeakSegmentContentProps {
  insight: InsightSchema;
}

const { score, buttonLabel } = constants.insights.weakSegment;

export const WeakSegmentContent = (props: WeakSegmentContentProps) => {
  const { insight } = props;

  const { push } = useRouter();
  const { palette } = useTheme();

  const handleRedirectSamples = (dataFilters: DataFilter[], topics: DataFilter[]) => {
    push(`${routes.samples}${buildDrillDownQuery(dataFilters, topics)}`);
  };

  return (
    <>
      <InsightsTagsContainer>
        {calculateDataFilterString(insight?.segment_filters)?.map((text: string) => (
          <InsightTag key={text} text={text} />
        ))}
        {Array.isArray(insight?.categorical_segment_filters) &&
          insight?.categorical_segment_filters?.map((topic, i) => (
            <InsightTag key={i} text={topicsInsightString(topic)} isTopic />
          ))}
      </InsightsTagsContainer>
      <InsightInnerContainer marginTop="auto">
        <ScoreSampleContainer>
          <StyledText text={score} type="body" />
          <StyledText type="bodyBold" color={palette.error.main} text={getPercentage(insight?.segment_score)} />
        </ScoreSampleContainer>
        <InsightViewButton
          variant="text"
          data-testid={`InsightButton${insight?.id}`}
          onClick={() => handleRedirectSamples(insight?.segment_filters, insight?.categorical_segment_filters)}
        >
          {buttonLabel}
        </InsightViewButton>
      </InsightInnerContainer>
    </>
  );
};
